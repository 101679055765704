<template>
	<div class="financial">
		<Row style='z-index: 11;' class='chuck'>
			<Form style="width: 100%;" ref="searchForm" :model='searchForm' :label-width="120" inline>
				<FormItem style='width: 40%;' prop='competCompany'>
					<div class="labelClass" slot='label'>
						我的企业
					</div>
					<Select size='large' label-in-value multiple :max-tag-count='1' filterable clearable
						style="width: 70%;" v-model="competCompany">
						<Option :key="index" v-for="(company,index) in companyList" :label="company.enterpriseName"
							:value="company.stock">{{company.enterpriseName}}
						</Option>
					</Select>
					<Button size='large' style="width: 25%;margin-left: 5%;" type="default"
						@click="clearSelected">清空</Button>
				</FormItem>
				<FormItem v-show='searchForm.competCompany.length != 0' style='width: 55%;' prop='competCompanyList'>
					<div class="labelClass" slot='label'>
						已选
					</div>
					<div class="selectBox">
						<Tag style='margin-left: 5px;' size='large' v-for="(company,index) in searchForm.competCompany"
							:key='index' closable @on-close="tagClose" :name='company.stock'>
							<Tooltip :content="company.enterpriseName">
								{{company.enterpriseName}}
							</Tooltip>
						</Tag>
					</div>
				</FormItem>
			</Form>
		</Row>
		<Row style='z-index: 10;' class='chuck'>
			<SearchPanel ref="SearchPanel" @search='search' :id='selectedCompantId' :defaultTimeDim='3'></SearchPanel>
		</Row>
		<Row v-show="searchForm.reportType" class='chuck'>
			<Row class="titleBox">
				<div class="title">
					<span class="info">财务分析 <span>{{`（${searchForm.dimStart}年至${searchForm.dimEnd}年）`}}</span></span>
				</div>
				<div class="functionBox">
					<div v-if="btn==1" class="item currency">
						币种：人民币
					</div>
					<div v-if="btn==1" class="item unit">
						<Select size='large' style="width: 120px;border-radius: 8px;" v-model="unit">
							<Option :key="unit.code" v-for="unit in unitList" :value="unit.name">{{unit.name}}</Option>
						</Select>
					</div>
					<div v-if="btn==1" class="item download">
						<Button size='large' type="info" @click="exportData">下载数据</Button>
					</div>
					<div v-if="btn==1" class="item selectFormType">
						<ComfirmButton ref='SelectFormType' size='large' :maskClosable='true' :width='900'
							:footerHide='true' Icon='md-arrow-dropdown' type='default' info='表格展现方式'>
							<div slot="content">
								<Row class="FormTypeBox">
									<Col v-for="i in 4" :key="i" class="item">
									<div @click="selectFormType(i)"
										:class="['child', 'selectBox' ,formType == i ? 'active':'']"></div>
									<div @click="selectFormType(i)"
										:class="['child', 'viewBox',`viewBox-${i}`,formType == i ? 'active':'']">
										<div class="mask"></div>
									</div>
									</Col>
								</Row>
							</div>
						</ComfirmButton>
					</div>

					<div class="item changeForm">
						<div title="切换成表格" :class="['btn','table',btn == 1 ? 'active' : '']" @click="switchTable(1)">
						</div>
						<div title="切换成图表" :class="['btn','chart',btn == 2 ? 'active' : '']" @click="switchTable(2)">
						</div>
					</div>
				</div>
			</Row>

			<Row class='contentPanel'>
				<keep-alive>
					<component ref="component" :is="componentTag"></component>
				</keep-alive>
			</Row>
		</Row>
		<Spin style='z-index: 9999;' fix v-if="spinShow"></Spin>
	</div>
</template>

<script>
	import {
		fin_ent_options,
		fin_ent_update,
		fin_ent_default_list,
		fin_race_list,
		fin_race_view,
		fin_race_options
	} from '../../../../api/compet.js'
	import SearchPanel from '../../finance/components/searchPanel.vue'
	import TablePanel from './components/tablePanel.vue'
	import ChartPanelGroup from './components/chartPanelGroup.vue'
	import ComfirmButton from '../../../../components/Comfirm.vue'
	import {
		downloadData,
		formatJson
	} from "@/utils/index";
	import dataReports from "@/mixins/dataReports";
	export default {
		name: 'financial',
		components: {
			SearchPanel,
			TablePanel,
			ChartPanelGroup,
			ComfirmButton,
		},
		mixins: [dataReports],
		data() {
			return {
				btn: 1,
				unit: '亿元',
				unitList: [{
						name: '元',
						code: 1
					},
					{
						name: '万元',
						code: 3
					},
					{
						name: '亿元',
						code: 5
					},
				],
				selectedCompantId: '',
				searchForm: {
					competCompany: [],
					dimStart: '',
					dimEnd: '',
					reportType: '',
					indicators: '',
				},
				competCompany: [],
				companyList: [],
				loading: false,
				interval: false,
				componentTag: 'TablePanel',
				indexList: [],
				index: '',
				tag: '',
				isInit: false,
			}
		},
		computed: {
			selectedIndexHaslabel() {
				return JSON.parse(JSON.stringify(this.$store.state.reHouseEnterprise.selectedIndexHaslabel))
			},
			formType() {
				return this.$store.state.reHouseEnterprise.formType
			},
			//控制是否首次进入请求数据
			canGetInitData() {
				if (this.selectedIndexHaslabel.length != 0 && this.competCompany.length != 0 && this.isInit) {
					return true
				} else {
					return false
				}
			},
			spinShow: {
				get() {
					return this.$store.state.reHouseEnterprise.spinShow;
				},
				set(newValue) {
					this.$store.state.reHouseEnterprise.spinShow = newValue;
				}
			},
		},
		watch: {
			competCompany(val, old) {
				if (val.length > 10) {
					this.competCompany.pop()
					this.$msg.error({
						text: "最多选择10个企业"
					});
					return
				}
				let arr = []
				val.forEach(item => {
					this.companyList.forEach(company => {
						if (company.stock == item) {
							arr.push(company)
						}
					})
				})
				this.searchForm.competCompany = arr
				if (arr.length != 0) {
					if (!this.interval) {
						this.interval = true
						fin_ent_update({
							entLists: arr
						}).then(() => {
							this.interval = false
						}).finally(() => {
							this.interval = false
						})
					}
				} else {
					fin_ent_update({
						entLists: []
					})
				}


			},
			unit(val) {
				this.search()
			},
			selectedIndexHaslabel(val) {
				let arr = []
				val.forEach(item => {
					item.list.forEach(_class => {
						arr.push(_class)
					})
				})
				this.indexList = arr
				this.index = this.indexList[0] ? this.indexList[0].columnName : ''
				this.tag = this.indexList[0] ? this.indexList[0].tableName : ''
			},
			canGetInitData(val) {
				this.$refs.SearchPanel.search()
			},
		},
		mounted() {
			this.init()
		},
		//清空所选的指标
		beforeDestroy() {
			this.$store.commit('reHouseEnterprise/changeState', {
				prop: "selectedIndex",
				value: []
			})
			this.$store.commit('reHouseEnterprise/changeState', {
				prop: "selectedIndexHaslabel",
				value: []
			})
		},
		methods: {
			init() {
				this.getOptions()
				this.getDefaultOptions()
			},
			//获取指标
			async getOptions() {
				let data = await fin_ent_options()
				this.companyList = data
			},
			//获取默认企业
			async getDefaultOptions() {
				this.competCompany = []
				let data = await fin_ent_default_list()
				data.forEach(item => {
					this.competCompany.push(item.stock)
				})
				this.isInit = true
			},
			//关闭tag
			tagClose(eve, name) {
				this.competCompany.forEach((item, index) => {
					if (item == name) {
						this.competCompany.splice(index, 1)
					}
				})
			},
			//清空指标
			clearSelected() {
				this.competCompany = []
				fin_ent_update({
					entLists: []
				}).then(() => {
					this.interval = false
				}).finally(() => {
					this.interval = false
				})
			},
			//查询
			async search(res) {
				if (res) {
					for (let key in res) {
						this.searchForm[key] = res[key]
					}
				}
				let params = {
					dimEnd: this.searchForm.dimEnd,
					dimStart: this.searchForm.dimStart,
					indicators: this.searchForm.indicators,
					reportType: this.searchForm.reportType,
					stock: this.competCompany,
					unit: this.unit,
				}
				if (params.stock.length == 0) {
					this.$msg.error({
						text: '请选择要查询的企业'
					})
					return
				}

				//检测时间周期
				if (params.dimStart == '' || params.dimEnd == '') {
					this.$msg.error({
						text: '请选择时间周期'
					});
					return
				}
				//检测所选指标
				let length = 0
				params.indicators.forEach(item => {
					length += item.list.length
				})
				if (length == 0) {
					this.$msg.error({
						text: '请选择财务指标'
					});
					return
				}
				this.spinShow = true
				this.switchTable(1)
				let data = await fin_race_list(params)
				if (Array.isArray(data)) {
					this.$store.commit('reHouseEnterprise/changeState', {
						prop: "raceData",
						value: data,
					})
				}
				this.isInit = false
			},
			//选择表格展现形式
			selectFormType(type) {
				this.$refs.SelectFormType.cancle()
				this.spinShow = true
				// this.$nextTick(() => {
				// 	this.spinShow = true
				// 	this.$store.commit('reHouseEnterprise/changeState', {
				// 		prop: "formType",
				// 		value: type,
				// 	});
				// })
				setTimeout(() => {
					this.$store.commit('reHouseEnterprise/changeState', {
						prop: "formType",
						value: type,
					});
				}, 100)
			},
			//下载数据
			async exportData() {
				//处理表头
				this.$btnSysLog(1,1)
				let head = []
				for (let key in this.$refs.component.data[0]) {
					head.push(key)
				}
				head.pop()
				//调整表头
				if (this.formType == 1 || this.formType == 2) {
					let item = head[1];
					head[1] = head[2];
					head[2] = item;
				} else {
					let item = head[0];
					head[0] = head[1];
					head[1] = item;
				}
				//处理数据
				let eData = []
				this.$refs.component.data.forEach(data => {
					let arr = []
					head.forEach(colums => {
						arr.push(data[colums])
					})
					eData.push(arr)
				})
				//把表头的英文换成中文
				head.forEach((item, index) => {
					this.$refs.component.columns.forEach(colums => {
						if (item == colums.key) {
							head[index] = colums.title
						}
					})
				})
				downloadData(head, eData, "财务报表")
			},
			//切换图表
			switchTable(type) {
				this.btn = type
				//清空所选指标list
				this.$store.commit('reHouseEnterprise/changeState', {
					prop: "raceChartBarIndexList",
					value: []
				})
				this.$store.commit('reHouseEnterprise/changeState', {
					prop: "raceChartLineIndexList",
					value: []
				})
				if (type == 1) {
					this.componentTag = 'TablePanel'
				} else if (type == 2) {
					this.componentTag = 'ChartPanelGroup'
					this.setViewParams()
				}
			},
			//设置图参数
			async setViewParams(column) {
				let params = {
					dimEnd: this.searchForm.dimEnd,
					dimStart: this.searchForm.dimStart,
					reportType: this.searchForm.reportType,
					stock: this.competCompany,
					unit: this.unit,
				}
				this.$store.commit('reHouseEnterprise/changeState', {
					prop: "raceChartSearchData",
					value: params
				})
				//设置所选指标
				//viewType0为折线图，1为柱状图
				let lineIndex = await fin_race_options({
					columnName: this.searchForm.indicators[0].list,
					viewType: 0
				})
				let barIndex = await fin_race_options({
					columnName: this.searchForm.indicators[0].list,
					viewType: 1
				})
				this.$store.commit('reHouseEnterprise/changeState', {
					prop: "raceChartBarIndexList",
					value: barIndex
				})
				this.$store.commit('reHouseEnterprise/changeState', {
					prop: "raceChartLineIndexList",
					value: lineIndex
				})


			}
		}
	}
</script>

<style scoped lang="scss">
	.financial {
		padding: 0px 20px 0px;
		position: relative;

		.chuck {
			width: 100%;
			position: relative;
			left: 50%;
			transform: translate(-50%);
			background: #ffffff;
			border-radius: 8px;
			box-shadow: -4px 0px 24px 0px rgba(51, 51, 51, 0.05);
			padding: 20px 50px;
			border: 1px solid rgba($color: #999999, $alpha: 0.1);
			margin-top: 30px;
			padding-top: 30px;
		}

		.labelClass {
			font-size: 16px;
			font-family: Source Han Sans CN, Source Han Sans CN-Medium;
			font-weight: 500;
			color: #747c8a;
		}

		.titleBox {
			width: 100%;
			display: flex;

		}

		.contentPanel {
			width: 100%;
			margin-top: 30px;
		}

		.title {
			height: 48px;
			flex: 1;

			.info {
				font-size: 18px;
				font-family: Source Han Sans CN, Source Han Sans CN-Medium;
				font-weight: 500;
				text-align: left;
				color: #333333;
				height: 48px;
				line-height: 48px;

			}

			.time {
				font-size: 14px;
				font-family: Source Han Sans CN, Source Han Sans CN-Normal;
				font-weight: Normal;
				text-align: left;
				color: #999999;
				height: 48px;
				line-height: 48px;
			}
		}

		.functionBox {
			height: 48px;
			flex: 1;
			position: relative;

			>.item {
				position: absolute;
			}

			.currency {
				font-size: 16px;
				font-family: Source Han Sans CN, Source Han Sans CN-Regular;
				font-weight: 400;
				text-align: left;
				color: #333333;
				right: 520px;
				height: 40px;
				line-height: 40px;
			}

			.unit {
				width: 120px;
				height: 40px;
				right: 385px;
			}

			.download {
				right: 270px;
			}

			.selectFormType {
				right: 100px;
			}

			.changeForm {
				width: 80px;
				height: 40px;
				right: 0;

				.btn {
					width: 40px;
					height: 40px;
					display: inline-block;
					background-repeat: no-repeat;
					background-position: center;
					background-size: 100% 100%;
					cursor: pointer;

					&.table {
						background-image: url(../../../../assets/image/finance/table.png);

						&.active {
							background-image: url(../../../../assets/image/finance/table_active.png);
						}
					}

					&.chart {
						background-image: url(../../../../assets/image/finance/chart.png);

						&.active {
							background-image: url(../../../../assets/image/finance/chart_active.png);
						}
					}
				}
			}
		}
	}

	.FormTypeBox {
		width: 100%;
		padding-bottom: 50px;

		>.item {
			width: 50%;
			font-size: 0;
			text-align: center;
			margin-top: 50px;
			cursor: pointer;

			.child {
				display: inline-block;
				vertical-align: middle;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: 100% 100%;
			}

			.selectBox {
				width: 34px;
				height: 34px;
				background-image: url(../../../../assets/image/finance/select.png);

				&.active {
					background-image: url(../../../../assets/image/finance/select_active.png);
				}
			}

			.viewBox {
				width: 304px;
				height: 136px;
				margin-left: 20px;
				opacity: 0.5;
				position: relative;

				&.viewBox-1 {
					background-image: url(../../../../assets/image/finance/formType-1.png);
				}

				&.viewBox-2 {
					background-image: url(../../../../assets/image/finance/formType-2.png);
				}

				&.viewBox-3 {
					background-image: url(../../../../assets/image/finance/formType-3.png);
				}

				&.viewBox-4 {
					background-image: url(../../../../assets/image/finance/formType-4.png);
				}

				&.active {
					opacity: 1 !important;

					.mask {
						opacity: 0 !important;
					}

				}

				&:hover {
					opacity: 0.8;
				}

				.mask {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: black;
					opacity: 0;
					border-radius: 8px;

					&:hover {
						opacity: 0.3;
					}
				}
			}
		}
	}

	/deep/ .demo-spin-container {
		display: inline-block;
		width: 200px;
		height: 100px;
		position: relative;
		border: 1px solid #eee;
	}
</style>
