<template>
	<div class="chartPanel">
		<div class="titleFun">
			<div class="chartFun">
				<span class="item info">选择指标：</span>
				<span class="item selectFun">
					<Select size='large' v-model="index" label-in-value>
						<Option :key="item.columnName" v-for="item in selfIndexList" :label='item.columnDesc'
							:value="item.columnName">
							<div slot>{{item.columnDesc}}</div>
						</Option>
					</Select>
				</span>
			</div>
		</div>
		<div id="raceChartsBar" ref="raceChartsBar">
		</div>
	</div>
</template>

<script>
	import {
		fin_race_view
	} from '../../../../../api/compet.js'
	export default {
		name: 'chartPanel',
		props: ['indexList', 'type'],
		computed: {
			selfIndexList() {
				return JSON.parse(JSON.stringify(this.indexList))
			},
			raceChartSearchData() {
				return JSON.parse(JSON.stringify(this.$store.state.reHouseEnterprise.raceChartSearchData))
			},
		},
		watch: {
			selfIndexList: {
				handler(val) {
					this.index = val[0].columnName
				},
				immediate: true
			},
			index: {
				handler(val) {
					this.searchView(val)
				},
				immediate: true
			}
		},
		data() {
			return {
				echartBar: '',
				echartLine: '',
				index: '',
				viewData: {},
				options: {
					color: ['#0485DC', '#719FF7', '#6FDCB2', '#F3AE7C', '#E97C65', '#7E97B3', '#7B7FBB', '#38B4D5',
						'#F5C736', '#E97C65'
					],
					legend: {
						type: 'scroll',
						top: 0,
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross'
						}
					},
					dataset: {

					},
					xAxis: {
						type: 'category'
					},
					yAxis: {
						type: 'value',
						min: 0,
						max: 1000,
						position: 'left',
						splitNumber: 10,
						splitLine: {
							show: false //去掉网格线
						}
					},
					// Declare several bar series, each will be mapped
					// to a column of dataset.source by default.
					series: []
				},
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				this.echartBar = this.$echarts.init(this.$refs.raceChartsBar)
				//监听window的resize，然后重置E chart的宽度``
				window.addEventListener("resize", this.echartBar.resize)
			},
			async searchView(e) {
				let params = this.raceChartSearchData
				params.columnName = e
				this.viewData = await fin_race_view(params)
				this.setEChartOption(this.viewData)
			},
			setEChartOption(data) {
				let option = JSON.parse(JSON.stringify(this.options))
				option.dataset = {
					source: []
				}
				option.series = []
				option.dataset.source = this.getDataSet(data)
				option.yAxis.name = data.dataName
				option.yAxis.max = this.getMaxY(data)
				option.yAxis.min = this.getMinY(data)
				for (let i = 0; i < data.head.length; i++) {
					option.series.push({
						type: this.type,
						seriesLayoutBy: 'row',
						// smooth: this.type == 'line' ? true : null,
						barMaxWidth: this.type == 'line' ? null : 100, //柱图最大宽度,
					})
				}
				// option.tooltip.formatter = function(params) {
				// 	// let str = params[0].name + "<br>"
				// 	// params.forEach((item,index) => {
				// 	// 	str += item.marker  + item.seriesName + ":" + item.data[index+1] + `${data.unit}`+ "<br>"
				// 	// })
				// 	// return str
				// 	// return params[0].name + "<br>" + params[0].marker + params[0].seriesName + ":" + params[0].data[
				// 	// 	1] +
				// 	// 	`${data.value.unit}` + "<br>" + params[1].marker +
				// 	// 	params[1].seriesName + ":" + params[1].data[1] + `${data.yoys.unit}`
				// }
				this.echartBar.setOption(option)
			},
			getMaxY(data) {
				let arr = []
				data.value.forEach(item => {
					item.dataList.forEach(value => {
						arr.push(value)
					})
				})
				let max = Math.max(...arr)
				// return Math.ceil(max * 1.3)
				if (max > 0) {
					let e = Math.ceil(max).toString().length
					return (Math.ceil(max * 1.3 / (Math.pow(10, e - 2))) * (Math.pow(10, e - 2))).toFixed(2)
				} else {
					let e = Math.ceil(max).toString().length
					return (Math.ceil(max * 0.7 / (Math.pow(10, e - 2))) * (Math.pow(10, e - 2))).toFixed(2)
				}
			},
			getMinY(data) {
				let arr = []
				data.value.forEach(item => {
					item.dataList.forEach(value => {
						arr.push(value)
					})
				})
				let min = Math.min(...arr)
				if (this.type == 'line') {
					// if (min > 0) {
					// 	let e = Math.ceil(min).toString().length
					// 	return Math.ceil(min * 0.7 / (Math.pow(10, e - 2))) * (Math.pow(10, e - 2))
					// } else {
					// 	let e = Math.ceil(min).toString().length
					// 	return Math.ceil(min * 2 / (Math.pow(10, e - 2))) * (Math.pow(10, e - 2))
					// }
					if (min > 0) {
						return (Math.floor(min / 1.3 * 10) / 10).toFixed(2)
					} else {
						return -(Math.ceil(Math.abs(min) * 1.3 * 10) / 10).toFixed(2)
					}
				} else {
					if (min > 0) {
						return 0
					} else {
						return - (Math.ceil(Math.abs(min) * 1.3 * 10) / 10).toFixed(2)
					}
				}


			},
			getDataSet(data) {
				let obj = {}
				obj.dimensions = ['企业']
				obj.source = []
				data.head.forEach(company => {
					data.value.forEach(item => {
						if (item.entName == company) {
							let arr = []
							arr.push(company)
							item.dataList.forEach(value => {
								arr.push(value)
							})
							obj.source.push(arr)
						}
					})
				})
				data.dateList.forEach(item => {
					obj.dimensions.push(item)
				})
				let Data = []
				Data.push(obj.dimensions)
				Data = Data.concat(obj.source)
				return Data
			}
		}

	}
</script>

<style scoped lang="scss">
	.chartPanel {
		width: 100%;

		#raceChartsBar {
			width: 100%;
			height: 450px;
			margin-top: 30px;
		}

		.titleFun {
			width: 100%;
			height: 40px;
			position: relative;
		}

		.chartFun {
			height: 40px;
			float: right;
			font-size: 0;

			.item {
				display: inline-block;
				vertical-align: middle;
			}

			.info {
				font-size: 16px;
				font-family: Source Han Sans CN, Source Han Sans CN-Regular;
				font-weight: 400;
				text-align: left;
				color: #333333;
				line-height: 40px;
				height: 40px;
			}

			.selectFun {
				width: 200px;
			}
		}

	}
</style>
