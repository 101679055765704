<template>
	<div class="chartPanelGroup">
		<div :key="index" v-for="(item,index) in chartType" class="chartPanelBox">
			<ChartPanel v-if="item.indexList.length !=0" :indexList='item.indexList' :type='item.type'>
			</ChartPanel>
		</div>
	</div>
</template>

<script>
	import ChartPanel from './chartPanel.vue'
	import {
		fin_race_options
	} from '../../../../../api/compet.js'
	export default {
		name: 'chartPanelGroup',
		components: {
			ChartPanel
		},
		computed: {
			raceChartBarIndexList() {
				return JSON.parse(JSON.stringify(this.$store.state.reHouseEnterprise.raceChartBarIndexList))
			},
			raceChartLineIndexList() {
				return JSON.parse(JSON.stringify(this.$store.state.reHouseEnterprise.raceChartLineIndexList))
			},
		},
		watch: {
			raceChartBarIndexList(val) {
				this.chartType[0].indexList = val
			},
			raceChartLineIndexList(val) {
				this.chartType[1].indexList = val
			},
		},
		data() {
			return {
				//viewType0为折线图，1为柱状图
				chartType: [{
						type: 'bar',
						viewType: 1,
						indexList: [],
					},
					{
						type: 'line',
						viewType: 0,
						indexList: [],
					},
				]
			}
		},
	}
</script>

<style scoped lang="scss">
	.chartPanelGroup {
		width: 100%;

		.chartPanelBox {
			width: 100%;
		}
	}
</style>
