<template>
	<div class="tablePanel">
		<Table border :columns="columns" :data="data" :span-method="handleSpan" :height='850'></Table>
	</div>
</template>

<script>
	export default {
		name: 'tablePanel',
		computed: {
			formType() {
				return this.$store.state.reHouseEnterprise.formType
			},
			raceData() {
				return JSON.parse(JSON.stringify(this.$store.state.reHouseEnterprise.raceData))
			},
			spinShow: {
				get() {
					return this.$store.state.reHouseEnterprise.spinShow;
				},
				set(newValue) {
					this.$store.state.reHouseEnterprise.spinShow = newValue;
				}
			},

		},
		watch: {
			formType: {
				handler(val) {
					this.changeType(val)
				},
			},
			raceData: {
				handler(val) {
					this.changeType(this.formType)
				},
			}
		},
		data() {
			return {
				data: [],
				columns: [],
			};
		},
		methods: {
			//合并函数
			handleSpan({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (columnIndex == 0) {
					//计算合并的行数列数
					let x = row.mergeCol == 0 ? 0 : row.one
					let y = row.mergeCol == 0 ? 0 : 1
					return [x, y]
				}
				
			},
			changeType(type) {
				this.columns = this.assembleColumns(this.raceData, type)
				let Data = this.formatData(this.raceData, type)
				if (type == 1 || type == 2) {
					this.data = this.assembleData(Data, 'columnClass', 'one')
				} else {
					this.data = Data
				}
				this.$nextTick(() => {
					this.spinShow = false
				})
			},
			formatData(data, type) {
				let Data = []
				let Dates = []
				let EntNames = []
				let Indexs = []
				//筛选出不重复的 key值,将其放到 keys数组中
				data.forEach(e => {
					if (!Dates.includes(e.date)) {
						Dates.push(e.date)
					}
					if (!EntNames.includes(e.entName)) {
						EntNames.push(e.entName)
					}
					if (!Indexs.includes(e.index)) {
						Indexs.push(e.index)
					}
				})
				let fnList = {
					1: this.formatData_1(data, Indexs),
					2: this.formatData_2(data, Indexs),
					3: this.formatData_3(data, EntNames),
					4: this.formatData_4(data, EntNames),
				}
				Data = fnList[type]
				return Data
			},
			//第一种表格形式
			formatData_1(data, keyList) {
				let _Data = []
				keyList.forEach(index => {
					let OBJ = {}
					let arr = []
					data.forEach(e => {
						if (e.index == index) {
							for (let _key in e) {
								if (_key == 'columnClass') {
									OBJ[_key] = e[_key]
								} else if (_key == 'index') {
									OBJ[_key] = e[_key]
								} else {
									OBJ[`${e.date}-${e.entName}`] = e.value
								}
							}
						}
					})
					_Data.push(OBJ)
				})
				return _Data
			},
			//第二种表格形式
			formatData_2(data, keyList) {
				let _Data = []
				keyList.forEach(index => {
					let OBJ = {}
					let arr = []
					data.forEach(e => {
						if (e.index == index) {
							for (let _key in e) {
								if (_key == 'columnClass') {
									OBJ[_key] = e[_key]
								} else if (_key == 'index') {
									OBJ[_key] = e[_key]
								} else {
									OBJ[`${e.entName}-${e.date}`] = e.value
								}
							}
						}
					})
					_Data.push(OBJ)
				})
				return _Data
			},
			//第三种表格形式
			formatData_3(data, keyList) {
				let _Data = []
				keyList.forEach(entName => {
					let OBJ = {}
					let arr = []
					data.forEach(e => {
						if (e.entName == entName) {
							for (let _key in e) {
								if (_key == 'entName') {
									OBJ[_key] = e[_key]
								} else {
									OBJ[`${e.date}-${e.index}`] = e.value
								}
							}
						}
					})
					_Data.push(OBJ)
				})
				return _Data
			},
			//第四种表格形式
			formatData_4(data, keyList) {
				let _Data = []
				keyList.forEach(entName => {
					let OBJ = {}
					let arr = []
					data.forEach(e => {
						if (e.entName == entName) {
							for (let _key in e) {
								if (_key == 'entName') {
									OBJ[_key] = e[_key]
								} else {
									OBJ[`${e.index}-${e.date}`] = e.value
								}
							}
						}
					})
					_Data.push(OBJ)
				})
				return _Data
			},



			assembleColumns(data, type) {
				let Dates = []
				let EntNames = []
				let Indexs = []
				let Columns = []
				let extraColumns = []
				data.forEach(e => {
					if (!Dates.includes(e.date)) {
						Dates.push(e.date)
					}
					if (!EntNames.includes(e.entName)) {
						EntNames.push(e.entName)
					}
					if (!Indexs.includes(e.index)) {
						Indexs.push(e.index)
					}
				})
				//第一种及第二种的固定表头
				if (type == 1 || type == 2) {
					Columns.push({
						key: 'columnClass',
						title: '指标分类',
						align: 'center',
						width: 200,
						fixed: 'left',
					}, {
						key: 'index',
						title: '指标',
						align: 'center',
						width: 200,
						fixed: 'left',
					})
				} else {
					//第三种及第四种的固定表头
					Columns.push({
						key: 'entName',
						title: '企业',
						align: 'center',
						width: 200,
						fixed: 'left',
					})
				}
				//变化的表头
				if (type == 1) {
					extraColumns = this.formatColumns(Dates, EntNames)
				} else if (type == 2)(
					extraColumns = this.formatColumns(EntNames, Dates)
				)
				else if (type == 3) {
					extraColumns = this.formatColumns(Dates, Indexs)
				} else if (type == 4) {
					extraColumns = this.formatColumns(Indexs, Dates)
				}
				return Columns.concat(extraColumns)
			},

			//处理表头的函数
			formatColumns(a, b) {
				let Columns = []
				a.forEach(ia => {
					let Children = []
					b.forEach(ib => {
						Children.push({
							key: `${ia}-${ib}`,
							title: ib,
							align: 'center',
							minWidth: 200
						})
					})
					Columns.push({
						key: ia,
						title: ia,
						children: Children,
						align: 'center',
					})
				})
				return Columns
			},
			//函数处理
			assembleData(data, spanKey, mergeCol) {
				//data为原始数据
				//spanKey为需要合并的key
				//mergeCol为合并的列
				this.Data = []
				let Data = []
				data.forEach(e => {
					let obj = {}
					for (let key in e) {
						obj[key] = e[key]
					}
					Data.push(obj)
				})
				let keys = []
				//筛选出不重复的 key值,将其放到 keys数组中
				Data.forEach(e => {
					if (!keys.includes(e[spanKey])) {
						keys.push(e[spanKey])
					}
				})
				let keyNums = []
				//将keys数组中的 key值设置默认合并0个单元格,放到 keyNums中
				keys.forEach(e => {
					keyNums.push({
						key: e,
						num: 0
					})
				})
				//计算每种 key值所在行需要合并的单元格数
				Data.forEach(e => {
					keyNums.forEach(n => {
						if (e[spanKey] == n.key) {
							n.num++
						}
					})
				})
				// //将计算后的合并单元格数整合到 data中
				Data.forEach(e => {
					keyNums.forEach(n => {
						if (e[spanKey] == n.key) {
							if (keys.includes(e[spanKey])) {
								e[mergeCol] = n.num
								//删除已经设置过的值(防止被合并的单元格进到这个 if 语句中)
								keys.splice(keys.indexOf(n.key), 1)
							} else {
								//被合并的单元格设置为 0
								e[mergeCol] = 0
							}
						}
					})
				})
				// //将整理后的数据交给表格渲染
				return Data
			},

		}
	}
</script>

<style scoped lang="scss">
	.tablePanel {
		width: 100%;
	}
</style>
