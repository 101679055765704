import {
	post,
	get
} from '@/utils/http'
//企业名称下拉框
export function fin_ent_options(params) {
	return get({
		url: '/service-insight/finance-report/data/fin/ent/options',
		params
	})
}
//更新默认列表
export function fin_ent_update(params) {
	return post({
		url: '/service-insight/finance-report/data/fin/ent/update',
		params
	})
}
//默认列表
export function fin_ent_default_list(params) {
	return get({
		url: '/service-insight/finance-report/data/fin/ent/default/list',
		params
	})
}

//竟企分析
export function fin_race_list(params) {
	return post({
		url: '/service-insight/finance-report/data/fin/race/list',
		params
	})
}

//竞企指标下拉
export function fin_race_options(params) {
	return post({
		url: '/service-insight/finance-report/data/fin/race/options',
		params
	})
}

//图表
export function fin_race_view(params) {
	return post({
		url: '/service-insight/finance-report/data/fin/race/view',
		params
	})
}





